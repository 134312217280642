<template>
  <div class="container">
    <div class="user-nav">
      <el-row type="flex" justify="space-between">
        <el-col :span="8">
          <el-select
            v-model="termName"
            placeholder="学期"
            style="width: 195px"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in termlist"
              :key="index"
              :label="item.termName"
              :value="item.termName"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="16" style="text-align: right">
          <div class="userinfo">
            <p>{{ paramsData.userName }}</p>
            <span>学籍号：{{ paramsData.studentCode }}</span>
            <span>{{ paramsData.className }}</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="main">
      <div class="table-box">
        <h4>身心健康</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>项目</th>
            <th>写实记录</th>
            <th>体质检测</th>
			<th>体质技能</th>
            <th>体育出勤率</th>
            <th>体育比赛</th>
            <th>心理测评</th>
            <th>健康知识测评</th>
            <th>卫生习惯</th>
            <th>总计</th>
          </tr>
          <tr>
            <td>数据条数</td>
            <td>{{ healthRecord.realisticRecordNum_1 }}</td>
            <td>{{ healthRecord.institutionalLevelNum_2 }}</td>
			<td>{{ healthRecord.sportSkillsNum }}</td>
            <td>{{ healthRecord.sportsAttendanceNum_3 }}</td>
            <td>{{ healthRecord.sportsMatcheNum_4 }}</td>
            <td>{{ healthRecord.psychologicalNum_5 }}</td>
            <td>{{ healthRecord.healthNum_6 }}</td>
            <td>{{ healthRecord.hygieneNum_7 }}</td>
            <td>{{ healthRecord.totalNum }}</td>
          </tr>
          <tr>
            <td>综素成绩（分）</td>
            <td>{{ healthScore.realisticRecordScore_1 }}</td>
            <td>{{ healthScore.institutionalLevelScore_2 }}</td>
			<td>{{ healthScore.sportSkillsScore }}</td>
            <td>{{ healthScore.sportsAttendanceScore_3 }}</td>
            <td>{{ healthScore.sportsMatcheScore_4 }}</td>
            <td>{{ healthScore.psychologicalScore_5 }}</td>
            <td>{{ healthScore.healthScore_6 }}</td>
            <td>{{ healthScore.hygieneScore_7 }}</td>
            <td>{{ healthScore.totalScore }}</td>
          </tr>
        </table>
      </div>
      <div class="more">
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <h4>相关记录</h4>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <a :href="pdfUrl" target="_blank">
              <el-button type="text">国家学生体质健康标准</el-button>
            </a>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" class="more-tit">
          <el-col :span="12">
            <h5>中学生体质水平检测</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <div class="more-flex">
              <span>总成绩：{{ physiqueUser.totalGradesScore }}</span>
              <span>综素成绩：{{ physiqueUser.totalIndexScore }}</span>
            </div>
          </el-col>
        </el-row>
        <div class="table-box">
          <table border="1" cellspacing="0" rules="all">
            <tr>
              <th style="width: 5%">项目</th>
              <th>检测项</th>
              <th>检测结果</th>
              <th>得分</th>
              <th>等级</th>
            </tr>
            <tr>
              <td>1</td>
              <td>体重</td>
              <td>{{ physiqueUser.studentWeight }}</td>
              <td>——</td>
              <td>——</td>
            </tr>
            <tr>
              <td>2</td>
              <td>身高</td>
              <td>{{ physiqueUser.studentHeight }}</td>
              <td>——</td>
              <td>——</td>
            </tr>
            <tr>
              <td>3</td>
              <td>体重指数(BMI)(kg/m2)</td>
              <td>{{ physiqueUser.type1GradesScore }}</td>
              <td>{{ physiqueUser.type1IndexScore }}</td>
              <td>{{ physiqueUser.type1Level }}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>肺活量(毫升)</td>
              <td>{{ physiqueUser.type2GradesScore }}</td>
              <td>{{ physiqueUser.type2IndexScore }}</td>
              <td>{{ physiqueUser.type2Level }}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>50米跑(秒)</td>
              <td>{{ physiqueUser.type3GradesScore }}</td>
              <td>{{ physiqueUser.type3IndexScore }}</td>
              <td>{{ physiqueUser.type3Level }}</td>
            </tr>
            <tr>
              <td>6</td>
              <td>坐立体前驱（厘米）</td>
              <td>{{ physiqueUser.type4GradesScore }}</td>
              <td>{{ physiqueUser.type4IndexScore }}</td>
              <td>{{ physiqueUser.type4Level }}</td>
            </tr>
            <tr>
              <td>7</td>
              <td>立定跳远（厘米）</td>
              <td>{{ physiqueUser.type5GradesScore }}</td>
              <td>{{ physiqueUser.type5IndexScore }}</td>
              <td>{{ physiqueUser.type5Level }}</td>
            </tr>
            <tr>
              <td>8</td>
              <td>引体向上(男)/一分钟仰卧起坐(女)</td>
              <td>{{ physiqueUser.type6GradesScore }}</td>
              <td>{{ physiqueUser.type6IndexScore }}</td>
              <td>{{ physiqueUser.type6Level }}</td>
            </tr>
            <tr>
              <td>9</td>
              <td>1000米(男)/800(女)(分秒)</td>
              <td>{{ physiqueUser.type7GradesScore }}</td>
              <td>{{ physiqueUser.type7IndexScore }}</td>
              <td>{{ physiqueUser.type7Level }}</td>
            </tr>
          </table>
        </div>
        <!-- <el-table
          :data="tableData"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="name" label="检测项"> </el-table-column>
          <el-table-column prop="cord" label="成绩"> </el-table-column>
          <el-table-column prop="city" label="得分"> </el-table-column>
          <el-table-column prop="date" label="等级"> </el-table-column>
        </el-table> -->

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>体育比赛</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <!-- <el-button @click="handleWeijiAdd" type="primary" plain
              ><i class="el-icon-circle-plus"></i> 添加违纪</el-button
            > -->
          </el-col>
        </el-row>
        <el-table
          :data="racelist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="matchName" label="赛事名称"> </el-table-column>
          <el-table-column prop="indexRuleLevel" label="赛事级别">
          </el-table-column>
          <el-table-column prop="matchTime" label="比赛时间"> </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="handleDetailCompetition(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                @click="handleRemoveCompetition(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>体育出勤率</h5>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-table
          :data="sportAttend"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column label="评级项目">
            <template><span>体育出勤率</span></template>
          </el-table-column>
          <el-table-column prop="termName" label="学期"> </el-table-column>
          <el-table-column prop="city" label="评价方式">
            <template><span>客观记录</span></template>
          </el-table-column>
          <el-table-column prop="sportRatio" label="体育课出勤率">
          </el-table-column>
          <el-table-column prop="middleRatio" label="课间操出勤率">
          </el-table-column>
          <el-table-column prop="sportAttendScore" label="综素得分">
          </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>学生测评</h5>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-table
          :data="testlist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column prop="name" label="测评名称"> </el-table-column>
          <el-table-column prop="evaTypeId" label="测评类别">
			  <template slot-scope="scope">
				  <span v-if="scope.row.evaTypeId == 400">心理测评</span>
				  <span v-if="scope.row.evaTypeId == 401">健康知识测评</span>
			  </template>
          </el-table-column>
          <el-table-column prop="term" label="学期"> </el-table-column>
          <el-table-column prop="indexScore" label="状态">
            <template slot-scope="scope">
              {{ scope.row.indexScore == "暂无" ? "未测评" : "已测评" }}
            </template>
          </el-table-column>
          <el-table-column prop="indexScore" label="综素得分">
          </el-table-column>
        </el-table>
		
		<el-row
		  type="flex"
		  justify="space-between"
		  class="more-tit more-tittop"
		>
		  <el-col :span="12">
		    <h5>学生体育技能成绩</h5>
		  </el-col>
		  <el-col :span="12" style="text-align: right"></el-col>
		</el-row>
		<div class="table-box sport-skill-table">
		  <table border="1" cellspacing="0" rules="all">
		    <tr>
				<th>项目1</th>
				<th>项目1成绩</th>
				<th>项目2</th>
				<th>项目2成绩</th>
				<th>项目3</th>
				<th>项目3成绩</th>
				<th>项目4</th>
				<th>项目4成绩</th>
		    </tr>
		    <tr v-if="skillProjectScore">
				<td>{{ skillProjectScore.projectOne }}</td>
				<td>{{ skillProjectScore.projectOneScore }}</td>
				<td>{{ skillProjectScore.projectTwo }}</td>
				<td>{{ skillProjectScore.projectTwoScore }}</td>
				<td>{{ skillProjectScore.projectThree }}</td>
				<td>{{ skillProjectScore.projectThreeScore }}</td>
				<td>{{ skillProjectScore.projectFour }}</td>
				<td>{{ skillProjectScore.projectFourScore }}</td>
		    </tr>
			<tr v-else>
				<td colspan="8">暂无数据</td>
			</tr>
		  </table>
		</div>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>卫生习惯不良记录</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button type="primary" plain @click="handleHygienicAdd"
              ><i class="el-icon-circle-plus"></i> 添加记录</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="breakListDtos"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="breakContext" label="记录内容">
          </el-table-column>
          <el-table-column prop="breakScore" label="扣分值"> </el-table-column>
          <el-table-column prop="createTime" label="发布时间">
          </el-table-column>
          <el-table-column prop="breakStatus" label="状态">
            <template slot-scope="scope">
              {{ scope.row.breakStatus == "0" ? "存在" : "撤销" }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="handleDetailHygienic(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                v-if="scope.row.breakStatus == '0'"
                @click="handleRevoke(scope.row)"
                type="text"
                size="small"
                >撤销</el-button
              >
              <el-button
                v-else
                @click="handleRecovery(scope.row)"
                type="text"
                size="small"
                >恢复</el-button
              >
              <el-button
                @click="handleRemoveHygienic(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 查看详情 -->
      <el-dialog :title="title" :visible.sync="open" width="600px" center>
        <el-form
          :model="form"
          style="
            border-top: 1px solid #e1e3e9;
            border-bottom: 1px solid #e1e3e9;
            padding-top: 5px;
          "
        >
          <el-form-item>
            <span class="sname">{{ form.userName }}</span>
            <span class="snumb">学籍号：{{ form.studentCode }}</span>
          </el-form-item>
          <!-- 卫生 -->
          <template v-if="!isRace">
            <el-form-item label="记录内容：">
              <span class="scontent">{{ form.breakContext }}</span>
            </el-form-item>
            <el-form-item label="扣分值：">
              <span class="scontent">{{ form.breakScore }}</span>
            </el-form-item>
            <el-form-item label="发布时间：">
              <span class="scontent">{{ form.createTime }}</span>
            </el-form-item>
            <el-form-item
              label="状态："
            >
              <span style="color: red">{{ form.breakStatus }}</span>
            </el-form-item>
          </template>
          <!-- 体育 -->
          <template v-if="isRace">
            <el-form-item label="比赛名称：">
              <span class="scontent">{{ form.matchName }}</span>
            </el-form-item>
            <el-form-item label="比赛级别：">
              <span class="scontent">{{ form.indexRuleLevel }}</span>
            </el-form-item>
			<el-form-item label="获奖等级：" prop="resultLevel">
				<span class="scontent">{{ form.resultLevel }}</span>
			</el-form-item>
            <el-form-item label="比赛时间：">
              <span class="scontent">{{ form.matchTime }}</span>
            </el-form-item>
            <el-form-item
              label="举办单位："
            >
              <span class="scontent">{{ form.awardedUnit }}</span>
            </el-form-item>
          </template>
          <el-form-item label="所在学期：">
            <span class="scontent">{{ form.termName }}</span>
          </el-form-item>
          <!-- <el-form-item label="所在学校：">
            <span class="scontent">{{ form.schoolName }}</span>
          </el-form-item> -->
          <el-form-item label="佐证材料：" v-if="form._showImgList">
          	<el-image
          	  v-for="(item, index) in form._imgFileList"
          		style="width: 100px; height: 100px;"
          		:preview-src-list="form._imgFileList"		
          	  :key="index"
          	  :src="item"
          	></el-image>
          </el-form-item>
          <el-form-item label="佐证材料：" v-if="form._showOtherFileList">
          	<a
          	v-for="(item, idx) in form._otherFileList"
          	:key="item"
          	target="_blank"
          	:download="'附件' + idx"
          	:href="item">附件{{ idx + 1 }} </a>
          </el-form-item>
					<el-form-item label="备注说明：">
						<span>{{ form.bz }}</span>
					</el-form-item>
					<template v-if="!isRace && form.breakStatus == '撤销'">
					  <el-form-item label="撤销原因：">
					    <span class="scontent">{{ form.revokeCause }}</span>
					  </el-form-item>
					  <el-form-item label="撤销备注说明：">
					    <span class="scontent">{{ form.revokeRemark }}</span>
					  </el-form-item>
					</template>
					<template v-if="isRace && form.auditStatus == 2">
					    <el-form-item label="撤销原因：">
					      <span class="scontent">{{ form.causeDes }}</span>
					    </el-form-item>
					</template>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="open = false">关 闭</el-button>
        </span>
      </el-dialog>

      <!-- 添加卫生习惯不良记录 -->
      <el-dialog :title="title" :visible.sync="openAdd" width="600px" center>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="100px"
          label-position="left"
        >
          <el-form-item
            label-width="0"
            style="
              border-top: 1px solid #e1e3e9;
              border-bottom: 1px dashed #e1e3e9;
            "
          >
            <span class="sname">{{ form.userName }}</span>
            <span class="snumb">学籍号：{{ form.studentCode }}</span>
          </el-form-item>
          <el-form-item label="记录内容：" prop="breakContext">
            <el-input v-model="form.breakContext"></el-input>
          </el-form-item>
          <el-form-item label="扣分值：" prop="breakScore">
            <el-select
              style="width: 100%"
              v-model="form.breakScore"
              placeholder="请选择扣分值"
            >
              <el-option
                v-for="index in 5"
                :key="index"
                :label="index"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在学期：" prop="termName">
            <span class="scontent">{{ form.termName }}</span>
          </el-form-item>
          <!-- <el-form-item label="所在学校：" prop="schoolName">
            <span class="scontent">{{ form.schoolName }}</span>
          </el-form-item> -->
          <el-form-item
            label="佐证材料："
          >
            <el-upload
              action="nothing"
              accept=".jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt"
              :limit="3"
              :multiple="true"
              :file-list="form.fileList"
              :on-change="handleUpChange"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :auto-upload="false"
            >
              <el-button size="small" type="primary">添加附件</el-button>
              <div slot="tip" class="el-upload__tip">
                <!-- 只能上传jpg/png文件，且不超过1MB，最多三张 -->
              </div>
            </el-upload>
          </el-form-item>
					<el-form-item label="备注说明：">
					  <el-input
					    v-model="form.bz"
					    type="textarea"
					    :autosize="{ minRows: 4, maxRows: 6 }"
					    placeholder="请输入备注"
					  ></el-input>
					</el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="openAdd = false">取 消</el-button>
          <el-button type="primary" @click="handleSubmit">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 撤销违纪 -->
      <el-dialog
        title="撤销原因"
        :visible.sync="openRevoke"
        width="500px"
        center
      >
        <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
          <el-form-item prop="revokeCause" label="请选择撤销原因">
            <br />
            <el-radio-group v-model="form.revokeCause">
              <el-radio label="经核实，违纪内容不存在"
                >经核实，违纪内容不存在</el-radio
              >
              <el-radio label="经后续观察，改过态度良好，改正效果明显"
                >经后续观察，改过态度良好，改正效果明显</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="撤销作用：" prop="termName">
            <span class="scontent">被扣除的分数将恢复</span>
          </el-form-item>
          <el-form-item prop="revokeRemark" label="备注说明">
            <el-input
              v-model="form.revokeRemark"
              type="textarea"
              autocomplete="off"
              :rows="3"
              placeholder="请输入备注说明，非必填"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="openRevoke = false">取 消</el-button>
          <el-button type="primary" @click="handleSubmitRevoke"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <div class="back">
        <el-button type="text" @click="backIndex">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getTerm } from "@/api/public/search";
import {
  getHealth,
  healthByUserId,
  getCompetionById,
  getWeijiByUserId,
  getStudentTest,
  addWeiji,
  getWejiDetail,
  getCompetionDetail,
  removeCompetion,
  removeWeiji,
  revokeWeiji,
  recoveryWeiji,
} from "@/api/teacher/quality/health";
export default {
  data() {
    return {
      pdfUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/国家学生体质健康标准.pdf`,
      imgSrc: this.imageSrc(),
      termName: "",
      tableData: [],
      open: false,
      openAdd: false,
      openRevoke: false,
      title: "",
      isRace: false,
      form: {},
      rules: {
        breakContext: [
          { required: true, message: "请输入违纪内容", trigger: "blur" },
        ],
        breakScore: [
          { required: true, message: "请选择要扣除的分值", trigger: "change" },
        ],

        revokeCause: [
          { required: true, message: "请选择撤销原因", trigger: "change" },
        ],
      },
      paramsData: {},
      termlist: [],
      healthyForm: {},
      healthRecord: {},
      healthScore: {},
      sportAttend: [],
      breakListDtos: [],
      physiqueUser: {},
	  skillProjectScore: {},
      racelist: [],
      testlist: [],
    };
  },
  created() {
    this.paramsData =
      this.$route.params.userName != undefined
        ? this.$route.params
        : JSON.parse(sessionStorage["params"]);
    this.termName = this.paramsData.termName;
    this.getTermList();
    this.getHealthy();
  },
  methods: {
    getHealthy() {
      this.sportAttend = [];
      getHealth({
        userId: this.paramsData.userId,
        term: this.paramsData.termName,
      }).then((res) => {
		let d = res.data
        this.racelist = d.matchList;
        this.testlist = d.evaluationDtoList;
        this.healthRecord = d.healthRecordNum;
        this.healthScore = d.healthIndexScore;
        this.sportAttend.push(d.sportattendDto);
        this.breakListDtos = d.breakListDtos;
        this.physiqueUser = d.physiqueUser;
		this.skillProjectScore = d.sportSkillsProjectUserScore
      });
    },
    handleChange(val) {
      this.paramsData.termName = val;
      this.getHealthy();
    },
	// 处理附件
	processAth(key) {
		let temp = key.split(",")
		let imgFileList = []
		let otherFileList = []
		// 材料按文件后缀名分流
		temp.forEach(v => {
			if (this.fileNotImg(v)) {
				otherFileList.push(this.imgSrc + v)
			} else {
				imgFileList.push(this.imgSrc + v)
			}
		})
		this.form._imgFileList = imgFileList
		this.form._otherFileList = otherFileList
		this.form._showImgList = imgFileList.length > 0
		this.form._showOtherFileList = otherFileList.length > 0
	},
    handleDetailCompetition(row) {
      this.title = "体育比赛";
      this.open = true;
      this.isRace = true;
      getCompetionDetail({ matchId: row.id }).then((res) => {
        this.form = res.data;
        if(this.form.proofFile) {
          this.processAth(this.form.proofFile)
        }
        this.open = true;
      });
    },
    handleRemoveCompetition(row) {
      this.$confirm(
        "删除该条记录后，学生对应的综素得分将被扣除，确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          removeCompetion({ matchId: row.id }).then((res) => {
            this.$message.success("删除成功");
            this.getHealthy();
          });
        })
        .catch(() => {
          return false;
        });
    },
    handleDetailHygienic(row) {
      this.title = "卫生习惯不良记录";
      this.open = true;
      this.isRace = false;
      getWejiDetail({ breakId: row.id }).then((res) => {
        this.form = res.data;
        if(this.form.proofFile) {
          this.processAth(this.form.proofFile)
        }
        this.open = true;
      });
    },
    handleRevoke(row) {
      this.reset()
      this.openRevoke = true;
      this.form.breakId = row.id;
    },
    handleSubmitRevoke() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          revokeWeiji({
            breakId: this.form.breakId,
            revokeCause: this.form.revokeCause,
            revokeRemark: this.form.revokeRemark,
          }).then((res) => {
            this.$message.success("撤销成功");
            this.getHealthy();
            this.openRevoke = false;
            this.resetForm("form");
          });
        } else {
          return false;
        }
      });
    },
    handleRecovery(row) {
      this.$confirm(
        "恢复该条记录后，学生对应的综素得分将被扣除，确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          recoveryWeiji({ breakId: row.id }).then((res) => {
            this.$message.success("恢复成功");
            this.getHealthy();
          });
        })
        .catch(() => {
          return false;
        });
    },
    handleRemoveHygienic(row) {
      this.$confirm(
        "删除该条记录后，学生对应被扣除的分数将恢复，确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          removeWeiji({ breakId: row.id }).then((res) => {
            this.$message.success("删除成功");
            this.getHealthy();
          });
        })
        .catch(() => {
          return false;
        });
    },
    handleHygienicAdd() {
      this.reset()
      this.title = "卫生习惯不良记录";
      this.openAdd = true;
      this.isRace = false;
      this.form.userName = this.paramsData.userName;
      this.form.studentCode = this.paramsData.studentCode;
      this.form.termName = this.paramsData.termName;
      this.form.userId = this.paramsData.userId;
      this.form.classId = this.paramsData.classId;
    },
    handleSubmit() {
      if(this.form.file) {
        this.form.file = this.compressImg(this.form.file)
      }
      setTimeout(()=>{
        this.$refs["form"].validate((valid) => {
          if (valid) {
            var formData = new FormData();
            if (this.form.file != undefined) {
              this.form.file.map((item) => {
                formData.append("file", item);
              });
            }
            formData.append("userId", this.form.userId);
            formData.append("classId", this.form.classId);
            formData.append("breakContext", this.form.breakContext);
            formData.append("breakScore", this.form.breakScore);
						if (this.form.bz) {
							formData.append("bz", this.form.bz)
						}
            addWeiji(formData).then((res) => {
              this.$message.success("添加成功");
              this.getHealthy();
              this.openAdd = false;
              this.resetForm("form");
            });
          } else {
            return false;
          }
        });        
      },1000)
    },
    reset() {
      this.form = {
        userId: undefined,
        classId: undefined,
        breakContext: undefined,
        breakScore: undefined,
        bz: undefined,
        file: [],
        revokeCause: undefined,
        revokeRemark: undefined,
      }
      this.resetForm('form')
    },
    // 上传图片
    handleUpChange(file, fileList) {
      var _this = this;
      _this.form.file = fileList;
      if(file.size / 1024 > 5 * 1024) {
        this.$message.warning('文件大小不得超过5MB')
        this.handleRemove(file)
      }
    },
    handleRemove(file, fileList) {
      this.form.file.forEach((item, index) => {
        if (item.name === file.raw.name) {
          this.form.file.splice(index, 1);
        }
      });
    },
    handleExceed() {
      this.$message.warning("最多只能上传三个文件");
    },
    backIndex() {
      this.$router.go(-1);
    },
    getTermList() {
      getTerm({}).then((res) => {
        this.termlist = res.data;
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .main {
    .table-box {
      table {
        tr {
          th {
            width: 10%;
          }
        }
      }
    }
    .more {
      .table-box {
        table {
          tr {
            th {
              width: 23%;
            }
          }
        }
      }
    }
  }
  .sport-skill-table {
  	  table {
  		  th {
  			  width: 12.5% !important;
  		  }
  	  }
  }
}
</style>
<style scoped>
.user-nav .el-select >>> .el-input {
  margin: 10px 12px;
}
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 300px;
}
</style>